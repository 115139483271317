<template>
    <div class="iframe-container">
      <iframe :src="iframeSrc" class="frame-item" scrolling="auto" frameborder="0" id="iframe" ></iframe>
    </div>

    <Footer></Footer>
</template>

<script>
import Footer from "../../components/Tabbar/index.vue";
import {defineComponent, reactive, ref, getCurrentInstance} from 'vue'
export default {
    name: "boss",
    components: {
        Footer
    },
    setup() {
        const {proxy} = getCurrentInstance();
        const iframeSrc = ref("");
        const tparams = proxy.$root.$route.params;
        const tpms = JSON.parse(tparams.tpms);

        if(tpms!=undefined){
          iframeSrc.value = tpms.url;

        }

        // const iframeSrc = "https://wx84cc4d5af40cdfe7.h5.xiaoe-tech.com/homepage?share_user_id=u_5e7c9338d5aa5_0Esz6uMTgq&entry=2&entry_type=2001";
        // // const iframeSrc = proxy.$root.$route.query.href!== undefined? proxy.$root.$route.query.href: '';
        // console.log(iframeSrc);
        return{
            iframeSrc
        }
    }
}
</script>

<style scoped>

.iframe-container{
    height: 100%;
    width: 100%; 
    padding: 0;
    padding-bottom: 50px;
    /*margin-bottom: 50px;*/
}
.frame-item {
  /*min-height: 100vh;*/
  width: 100%;
  height: calc(100vh - 100px);
}
</style>